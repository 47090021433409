<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8" type="gradient-success">
      <div class="notification-refresh border p-2 mb-4">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <div class="logo-notification">
              <i class="fas fa-address-card"></i>
              Video Digital {{ provinceNameGetters }}
            </div>
            <div class="information-notification ml-auto">
              <router-link :to="{ name: 'videoDigitalAddPage' }">
                <b-button
                  v-b-tooltip.hover
                  class="btn-sync ml-1 bg-success"
                  size="sm"
                  title="Tambah Data"
                >
                  Tambah Data
                </b-button>
              </router-link>
              <b-button
                v-b-tooltip.hover
                class="btn-sync ml-1 bg-success"
                size="sm"
                title="Refresh Data"
                @click="sinkrokinsasi()"
              >
                <i class="fas fa-sync-alt" title="Refresh Data"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-12">
          <div class="header-information d-flex align-items-center mb-3">
            <div class="header-info">
              <h1></h1>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->

      <div class="row">
        <div class="col-12">
          <MenuTabs menu-active="Video" />
        </div>
        <div class="col-12">
          <div class="card">
            <!-- /.card-header -->
            <div class="card-body">
              <TableData />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TableData from "./Table/index.vue";
import { ROLES } from "@/services/employeeService";
import MenuTabs from "../MenuTabs.vue";

export default {
  data() {
    return {
      token: null,
    };
  },
  computed: {
    ...mapState({
      listSchool: (state) => state.kioskStore.data.listSchool,
      kioskStore: (state) => state.kioskStore.data,
    }),
    ...mapGetters([
      "provinceNameGetters",
      "govermentIdGetters",
      "rolesArrayGetters",
    ]),
  },
  components: {
    MenuTabs,
    TableData,
  },
  methods: {
    ...mapActions("kioskStore", ["fetchListContentVideo", "fetchListSchools"]),

    sinkrokinsasi() {
      this.$store.dispatch("kioskStore/fetchListContentVideo");
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // Periksa apakah salah satu role ada di vm.rolesArrayGetters
      const hasValidRole = [
        ROLES.admin,
        ROLES.sd,
        ROLES.smk,
        ROLES.sma,
        ROLES.smp,
        ROLES.slb,
      ].some((role) => vm.rolesArrayGetters.includes(role));

      if (hasValidRole) {
        vm.$router.replace({
          name: "anggotaEmployee",
          params: { id: vm.govermentIdGetters },
        });
      } else {
        // Lanjutkan navigasi normal
        next();
      }
    });
  },
  mounted() {
    if (!this.listSchool.items.length) {
      this.fetchListSchools();
    }
    if (!this.kioskStore.items.length) {
      this.fetchListContentVideo();
    }
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/generate-token.scss";
</style>
