<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8" type="gradient-success">
      <div class="notification-refresh border p-2 mb-4">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <div class="logo-notification">
              <i class="fas fa-address-card"></i>
              Upload Video
            </div>
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--8">
      <div>
        <div class="card">
          <div class="card-body">
            <form
              id="formCreateContentImage"
              class="form pb-2"
              @submit.prevent="submitData"
            >
              <div class="form-group">
                <label for="subject">Dibagikan ke</label>
                <div>
                  <el-radio-group
                    v-model="kioskStore.contentVideo.formData.destinationType"
                    size="medium"
                  >
                    <el-radio-button label="schools"
                      >Pilih Sekolah
                    </el-radio-button>
                    <el-radio-button label="schoolType"
                      >Jenjang Pendidikan
                    </el-radio-button>
                  </el-radio-group>
                </div>
              </div>

              <!--Berdasarkan Sekolah-->
              <div
                v-if="kioskStore.contentVideo.formData.destinationType === 'schools'"
                class="form-group"
              >
                <label for="subject">Pilih Sekolah</label>
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-3">
                    <span
                      @click="showModalSchool = true"
                      class="btn btn-primary btn-sm cursor-pointer"
                    >
                      Cari
                    </span>
                    <div>
                      <el-badge :value="kioskStore.contentVideo.selectedIds.length" class="item">
                        <el-button @click="showModalSchool = true" size="small">Terpilih</el-button>
                      </el-badge>
                    </div>
                  </div>
                  <small>Data akan ditampilkan pada sekolah yang terpilih</small>
                </div>
              </div>
              <div v-else class="form-group">
                <label for="subject">Jenjang Pendidikan</label>
                <div class="w-100">
                  <el-select
                    v-model="kioskStore.contentVideo.formData.schoolType"
                    aria-autocomplete="none"
                    class="w-100"
                    placeholder="Pilih Jenjang"
                  >
                    <el-option
                      v-for="item in ['SMA', 'SMK', 'SLB']"
                      :key="`type_${item}`"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <small>Data akan ditampilkan pada sekolah ({{kioskStore.contentVideo.formData.schoolType}})</small>
                </div>
              </div>

              <div class="form-group">
                <label for="subject">File Banner Digital (tipe: .mp4)</label>
                <input
                  id="file"
                  class="form-control mr-3"
                  placeholder="Isi data..."
                  required
                  type="file"
                  @change="handleFileChange"
                  accept="video/mp4"
                />
                <small>Agar Gambar tampil bagus gunakan ukuran ( 1080 x 1920 )</small>
              </div>

              <button
                class="btn btn-primary pl-2 pr-2 float-right btn-sm"
                type="submit"
              >
                Upload Data
              </button>
            </form>
          </div>
        </div>

        <Modal
          :onClose="() => (showModalSchool = false)"
          :show="showModalSchool"
          :title="`Daftar Sekolah`"
          width="75%"
        >
          <TableSelectSchools
            v-if="showModalSchool"
            :onClose="() => (showModalSchool = false)"
            :selectSchoolIds="kioskStore.contentVideo.selectedIds"
            @update:SchoolIdSelected="updateSelectIds"
            @closeModal="() => (showModalSchool = false)"
          />
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Swal from "sweetalert2";
import { ShowLoadingSwal } from "@/utils/Api";
import { ROLES } from "@/services/employeeService";
import Modal from "@/components/ModalUI";
import TableSelectSchools from "@/views/Goverment/BannerDigital/TableSelectSchools/index.vue";

export default {
  name: "FormBannerDigital",
  props: ["onClose"],
  data() {
    return {
      loadingFormData: false,
      showModalSchool: false,
    };
  },
  components: {
    TableSelectSchools,
    Modal,
  },
  computed: {
    ...mapState({
      kioskStore: (state) => state.kioskStore.data,
      listSchool: (state) => state.kioskStore.data.listSchool,
    }),
    ...mapGetters([
      "provinceNameGetters",
      "govermentIdGetters",
      "rolesArrayGetters",
    ]),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // Periksa apakah salah satu role ada di vm.rolesArrayGetters
      const hasValidRole = [
        ROLES.admin,
        ROLES.sd,
        ROLES.smk,
        ROLES.sma,
        ROLES.smp,
        ROLES.slb,
      ].some((role) => vm.rolesArrayGetters.includes(role));

      if (hasValidRole) {
        vm.$router.replace({
          name: "anggotaEmployee",
          params: { id: vm.govermentIdGetters },
        });
      } else {
        // Lanjutkan navigasi normal
        next();
      }
    });
  },
  mounted() {
    if (!this.listSchool.items.length) {
      this.fetchListSchools();
    }
  },
  methods: {
    ...mapActions("kioskStore", ["postContentVideo"]),
    ...mapActions("kioskStore", ["fetchListContentVideo", "fetchListSchools"]),
    ...mapMutations("kioskStore", ["changeKioskListSchools", "changeKioskContentVideo"]),

    updateSelectIds(schoolIds) {
      let listIds = []
      if (Array.isArray(schoolIds)) {
        listIds = schoolIds
      }
      this.changeKioskContentVideo({
        selectedIds: listIds
      })
    },

    sinkrokinsasi() {
      this.$store.dispatch("kioskStore/fetchListContentVideo");
    },

    handleFileChange(event) {
      const file = event.target.files[0]; // Get the first file from the file input
      this.$store.commit("kioskStore/changeKioskContentVideo", {
        formData: {
          ...this.kioskStore.contentVideo.formData,
          file: file,
        },
      });
    },

    async submitData() {
      if (this.loadingFormData) {
        console.log("call enpoint duplicate: STOP");
        return false;
      }
      if (this.kioskStore.contentVideo.formData.destinationType==='schools') {
        if (!this.kioskStore.contentVideo.selectedIds.length) {
          this.$message("Pastikan sekolah harus dipilih", "info");
          return false
        }
      }

      this.loadingFormData = true;
      ShowLoadingSwal();
      let result = await this.postContentVideo()
      this.loadingFormData = false;
      if (result) {
        Swal.close()
        this.$router.push({name: 'videoDigitalPage'})
      }
    },
  },
};
</script>
