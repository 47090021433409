<template>
  <div>
    <div class="row mt-3 ml-0 mr-0">
      <b-alert
        class="w-100"
        v-if="!!contentVideo.itemsErrorMessage"
        dismissible
        show
        variant="danger"
      >
        <span class="alert-text">{{ contentVideo.itemsErrorMessage }}</span>
      </b-alert>

      <b-table
        :busy="contentVideo.loading"
        :fields="fields"
        :items="contentVideo.items"
        :no-border-collapse="noCollapse"
        :sticky-header="false"
        class="table-content-video"
        hover
        responsive
        show-empty
        striped
      >
        <template #table-busy>
          <div class="text-center text-muted my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #empty>
          <p class="text-center">Tidak ditemukan data yang sesuai.</p>
        </template>

        <template #cell(no)="data">
          <span>{{ data.index + 1 }}</span>
        </template>

        <template #cell(file)="data">
          <a target="_blank" :href="data.item.content_path">
            <img
              alt="image thumbnail"
              :src="getThumbnail(data.item.content_path)"
              style="width: 35%"
            />
          </a>
        </template>

        <template #cell(dibagikan)="data">
          <span v-if="data.item.school_type">
            {{ data.item.school_type }}
          </span>
          <span
            v-else-if="data.item.school_ids.length"
            v-html="searchSchoolName(data.item.school_ids)"
          ></span>
          <span v-else>Sekolah di-{{ provinceNameGetters }}</span> <br />
        </template>

        <template #cell(dibuat)="data">
          <span>{{ data.item.created_at }}</span>
        </template>

        <template v-slot:cell(aksi)="data">
          <b-button
            @click="onDelete(data.item)"
            v-b-tooltip.hover
            class="btn btn-danger btn-sm text-white"
            title="Hapus Data"
          >
            <i class="fas fa-trash"></i>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import { ShowLoadingSwal } from "@/utils/Api";

export default {
  props: {
    searchSchoolType: {
      type: String,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState({
      kioskStore: (state) => state.kioskStore.data,
      contentVideo: (state) => state.kioskStore.data.contentVideo,
    }),
    ...mapGetters(["provinceNameGetters"]),
  },
  data() {
    return {
      fields: [
        { key: "no", sortable: false, width: "50px" },
        { key: "file", sortable: false, width: "150px", minWidth: "100px" },
        { key: "dibagikan", sortable: false, width: "150px" },
        { key: "dibuat", sortable: false },
        { key: "aksi", sortable: false, width: "150px", minWidth: "100px" },
      ],
      stickyHeader: true,
      noCollapse: true,
      showmodal: false,
      loadingDelete: false,
    };
  },
  methods: {
    ...mapActions("kioskStore", ["fetchListContentVideo", "deleteContent"]),

    getThumbnail(filePath) {
      return this.contentVideo.listThumbnailObject[filePath] || this.defaultImage;
    },
    async onDelete(item) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Video Digital akan dihapus permanen.",
        type: "warning",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (this.loadingDelete) return;

            ShowLoadingSwal();
            this.loadingDelete = true;
            const result = await this.deleteContent({contentId: item.id, type: 'video'});
            if (result) Swal.close();
            this.loadingDelete = false;
          }
        })
        .finally(() => {});
    },
    searchSchoolName(schoolIds) {
      // Filter schools by matching IDs, then map to their names
      const schoolNames = this.kioskStore.listSchool.items
        .filter((school) => schoolIds.includes(school.row_id))
        .map((school) => school.name);

      // Join names with a comma and return
      return schoolNames.join("<br> ");
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/_rkas-table.scss";
</style>
